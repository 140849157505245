import React from 'react';
import Navbar from './global-components/navbar';
import UnitedAvenues from './section-components/united-avenues';

const United_Avenues = () => {
    return <div>
        <Navbar />
        <UnitedAvenues />
        
        
    </div>
}

export default United_Avenues;

