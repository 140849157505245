import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomeV7 from './components/home-v7';
import About_v1 from './components/about';
import Contact from './components/contact';
import United_Avenues from './components/united-avenues';
import Anvita_Ivana from './components/anvita-ivana';
import Ghr_Callisto from './components/ghr-callisto';
const root = ReactDOMClient.createRoot(document.getElementById("root"));

root.render(


    <Router basename="/">
                    <div>
     	                <Routes>
                        
                            <Route path="/" element={<HomeV7/>} />
                            <Route exact path="/about" element={<About_v1/>} />
                            <Route exact path="/contact" element={ <Contact/> } />
                            <Route exact path="united-avenues" element={ <United_Avenues/>} />
                            <Route exact path="anvita-ivana" element={ <Anvita_Ivana/>} />
                            <Route exact path="ghr-callisto" element={ <Ghr_Callisto/>} />
     	                </Routes>
     	                </div>
                     </Router>
);

export default root;
