import React from 'react';
import Navbar from './global-components/navbar';
import GhrCallisto from './section-components/ghr-callisto';
const Ghr_Callisto = () => {
    return <div>
        <Navbar />
        
        <GhrCallisto />
        
    </div>
}

export default Ghr_Callisto;

