import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FeaturesV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    let customClass = this.props.customClass ? this.props.customClass :''

    return <div className={ customClass } >
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			         
			          <h1 className="section-title">Our Current Projects</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/23.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="/united-avenues">United Avenues</Link></h3>
			            <p>14 Floors High-rise Gated Community & 2 Basement Parkings</p>
						<p>In Narsingi, Hyderabad</p>
			            <Link className="ltn__service-btn" to="/united-avenues">View more <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/23.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="/anvita-ivana">Anvita Ivana</Link></h3>
			            <p>By Anvita Group - 2, 3, 4 BHK Apartments. Possesion from Dec 2024</p>
						<p>In Kolluru, West Hyderabad, Hyderabad</p>
			            <Link className="ltn__service-btn" to="/anvita-ivana">View more <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/23.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="/ghr-callisto">GHR Callisto</Link></h3>
			            <p>Smart Life In Nature. GHR Callisto is a commune that’s planned for the future</p>
						<p>In Kolluru, Tellapur.</p>
						
						
			            <Link className="ltn__service-btn" to="/ghr-callisto">View more <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>
			    </div>			
			  </div>
			  
			</div>
        }
}

export default FeaturesV1