import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends Component {
	
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
		<div>
           <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
			<div className="ltn__header-top-area section-bg-6 top-area-color-white---">
				<div className="container">
				<div className="row">
					<div className="col-md-7">
					<div className="ltn__top-bar-menu">
						<ul>
						<li><a href="mailto:enquiry@truevaepromoters.in"><i className="icon-mail" /> enquiry@truevaepromoters.in</a></li>
						<li><a href="tel:+91 9294787878"><i className="fa-sharp fa-phone fa-solid fa-rotate-90" /> +91 9294787878</a></li>
						
						</ul>
						
					</div>
					</div>
					
				</div>
				</div>
			</div>
			<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
				<div className="container">
				<div className="row">
					<div className="col">
					<div className="site-logo-wrap">
						<div className="site-logo go-top">
							<Link to="/"><img src={publicUrl+"assets/img/logo-2.png"} height="65" width="65" alt="Logo" /></Link>
							<span><b>RERA Certified</b></span>
						</div>						
					</div>
					</div>
					<div className="col header-menu-column">
					<div className="header-menu d-none d-xl-block">
						<nav>
						<div className="ltn__main-menu go-top">
							<ul>
							<li className="menu-icon"><Link to="/">Home</Link></li>
							<li className="menu-icon"><Link to="/about">About</Link></li>
							<li><Link to="/contact">Contact</Link></li>
							</ul>
						</div>
						</nav>
					</div>
					</div>
					<div className="col ltn__header-options ltn__header-options-2 mb-sm-20">
					
					
					<div className="mobile-menu-toggle d-xl-none">
					
						<nav>
						
						<Link to="/" style={{padding:"10px", }}>Home</Link>
						<Link to="/about" style={{padding:"10px", }} >About</Link>
						<Link to="/contact"style={{padding:"10px", }} >Contact</Link>
						
						</nav>
					</div>
					</div>
				</div>
				</div>
			</div>
			</header>
			

		</div>
        )
    }
}


export default Navbar