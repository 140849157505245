import React from 'react';
import Navbar from './global-components/navbar';

import AboutV2 from './section-components/about-v2';

const About_v1 = () => {
    return <div>
        <Navbar />
        
        <AboutV2/>
        
       
    </div>
}

export default About_v1

