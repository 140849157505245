import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ContactForm from './contact-form';


class GhrCallisto extends Component {

render() {  


let publicUrl = process.env.PUBLIC_URL+'/'



return <div className="ltn__page-details-area ltn__service-details-area mb-105">
				
			<div className='container' id="united-avenues-heading">
				<div className='row' id="un-main-desc">
					<h1 className="text-center">GHR Callisto</h1>
                    <h6 className="text-center">Smart Life In Nature</h6>
					<p className="text-center d-block">
                        GHR Callisto is a complete and an expansive residential 
                        commune that’s planned for the future
					</p>
					
				</div>
			</div>
			<div className="container">
				<h4 id="project-desc-heading">Project description: </h4>
				<p className='text-left'>GHR Callisto is a real estate brand that creates a sustainable environment 
                                        by providing modern and eco-friendly amenities. At GHR Callisto, 
                                        we offer premium smart living in the lap of nature in Kollur, Tellapur.
				</p>
				<p className='text-left'>
                Designed to enhance the lifestyle of its community members by providing a plethora of modern amenities 
                and features. We have a clubhouse equipped with fun and attractive indoor games, where you can spend 
                time with your friends & family and spend some quality time. You can also indulge in other amenities 
                like a swimming pool, cricket, gym, spa, and much more.
				</p>
			</div>
				
			<ContactForm formHeading="Book Site Visit" />
				
			<div className="image-carousel">
				<div className="container">
				

					<Carousel showThumbs={false}>
						<div>
							<img className='carousel-image' alt="GHR Callisto" 
                                 src={publicUrl+"assets/img/images/ghc-callisto/1.png"} />
							<p className="legend">GHR Callisto</p>
						</div>

					</Carousel>
					
				</div>
			</div>
						
			
                {/*Project location start*/}
                <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-30">
			         <div className="container">
			            <div className="row">
			                <div className="col-lg-12">
                                <div className="section-title-area ltn__section-title-2--- text-center">
                                     <h1 className="section-title">Project Location:</h1>
                                </div>
			                </div>
			            </div>
			            <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center">
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
			                        <img src={publicUrl+"assets/img/images/ghc-callisto/location/hi-techcity.svg"} 
                                        alt="hi-tech city" width="50px" height="40px" class="m-2"></img>
			                            <p className="category-title"><b>Hi-Tech City: <br/></b><span className="category-brief">
			                                30 mins away
			                             </span></p>
                                </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
			                        <img src={publicUrl+"assets/img/images/ghc-callisto/location/fd.svg"} 
                                        alt="financial district" width="50px" height="40px" class="m-2"></img>
                                    <p className="category-title"><b>Financial District: <br/></b><span className="category-brief">
			                        20 mins away
			                        </span></p>
			                    </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
			                        <img src={publicUrl+"assets/img/images/ghc-callisto/location/dps.svg"} 
                                        alt="DPS" width="50px" height="40px" class="m-2"></img>
                                    <p className="category-title"><b>Delhi Public School: <br/></b><span className="category-brief">
			                            5 mins away
			                        </span></p>
			                    </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
			                        <img src={publicUrl+"assets/img/images/ghc-callisto/location/icfai.svg"} 
                                        alt="icfai" width="50px" height="40px" class="m-2"></img>
                                    <p className="category-title"><b>ICFAI Business School: <br/></b><span className="category-brief">
			                            10 mins away
			                        </span></p>
			                    </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
			                         <img src={publicUrl+"assets/img/images/ghc-callisto/location/skyzone.svg"} 
                                          alt="sky zone" width="50px" height="40px" class="m-2"></img>
                                     <p className="category-title"><b>Sky Zone: <br/></b><span className="category-brief">
			                            10 mins away
			                         </span></p>
			                    </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
			                         <img src={publicUrl+"assets/img/images/ghc-callisto/location/hyatt.svg"} 
                                        alt="hyatt" width="50px" height="40px" class="m-2"></img>
                                      <p className="category-title"><b>Hyatt Gachibowli: <br/></b><span className="category-brief">
			                            20 mins away
			                          </span></p>
			                    </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
			                        <img src={publicUrl+"assets/img/images/ghc-callisto/location/continental.svg"} 
                                            alt="continental" width="50px" height="40px" class="m-2"></img>
                                    <p className="category-title"><b>Continental Hospitals: <br/></b><span className="category-brief">
			                            20 mins away
			                        </span></p>
			                    </div>
			                </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                <div className="ltn__category-item ltn__category-item-5 text-center">
			                         <img src={publicUrl+"assets/img/images/ghc-callisto/location/rgia.svg"} 
                                          alt="rgia" width="50px" height="40px" class="m-2"></img>
                                     <p className="category-title"><b>RGIA: <br/></b><span className="category-brief">
			                            45 mins away
			                         </span></p>
			                    </div>
			                </div>
			            </div>
			        </div>
			    </div>



        {/* Amenities section start */}
                    
                 <div className="ltn__search-by-place-area before-bg-top bg-image-top pt-30">
			        <div className="container">
			            <div className="row">
			                <div className="col-lg-12">
			                    <div className="section-title-area ltn__section-title-2--- text-center---">
			                        <h1 className="section-title text-center">Project Amenities:</h1>
			                    </div>
			                </div>
			            </div>
			            <div className="row ltn__search-by-place-slider-1-active slick-arrow-1 go-top">
			                <div className="col-lg-4">
			                    <div className="ltn__search-by-place-item">
			                        <div className="search-by-place-img">
			                            <img src={publicUrl+"assets/img/images/ghc-callisto/amenities/clubhouse.png"} 
                                             alt="club house" />
			                        </div>
			                        <div className="search-by-place-info">
			                            <h4>Club House</h4>
			                            <div className="search-by-place-btn">
			                                <small>GHR Callisto is built with the purpose of building a community that 
                                                    can socialise occasionally. You can play a variety of fun indoor games
                                                    in our clubhouse and make new friends in our society.
                                            </small>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			                <div className="col-lg-4">
			                    <div className="ltn__search-by-place-item">
			                        <div className="search-by-place-img">
			                            <img src={publicUrl+"assets/img/images/ghc-callisto/amenities/swimming-pool.png"}
                                             alt="swimming pools" />
			                        </div>
			                        <div className="search-by-place-info">
			                            <h4>Three Swimming Pools</h4>
			                            <div className="search-by-place-btn">
			                                <small>GHR Callisto aims to provide you with the lifestyle that you deserve. 
                                                    You can de-stress yourself by taking a dip in the refreshing water 
                                                    of swimming pools.
                                            </small>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			                <div className="col-lg-4">
			                    <div className="ltn__search-by-place-item">
			                        <div className="search-by-place-img">
			                            <img src={publicUrl+"assets/img/images/ghc-callisto/amenities/security.png"} 
                                             alt="security" />
			                        </div>
			                        <div className="search-by-place-info">
			                            <h4>Security</h4>
			                                <div className="search-by-place-btn">
			                                    <small>GHR Callisto, prioritises your security before everything else. 
                                                   With 24/7security services like surveillance cameras and more you get to 
                                                    live in a safe environment.
                                                </small>
			                                </div>
			                        </div>
			                    </div>
			                </div>
			                <div className="col-lg-4">
			                    <div className="ltn__search-by-place-item">
			                        <div className="search-by-place-img">
			                            <img src={publicUrl+"assets/img/images/ghc-callisto/amenities/amphitheater.png"} 
                                             alt="club house" />
			                        </div>
			                        <div className="search-by-place-info">
			                            <h4>Amphitheater</h4>
			                            <div className="search-by-place-btn">
                                            <small>At GHR Callisto, you can either witness a live performance or host your own 
                                                    show in our amphitheatre.
                                            </small>
			                            </div>
			                        </div>
			                    </div>
			                </div>
                            <div className="col-lg-4">
			                    <div className="ltn__search-by-place-item">
			                        <div className="search-by-place-img">
			                            <img src={publicUrl+"assets/img/images/ghc-callisto/amenities/co-workingspace.png"} 
                                             alt="Co-Working" />
			    	    	        </div>
			                        <div className="search-by-place-info">
			            	            <h4>Co-Working</h4>
			                            <div className="search-by-place-btn">
			                                <small>At GHR Callisto, you can switch from WFO mode to WFH. 
                                                   Indoor and Outdoor spaces that give you the office zone without 
                                                   compromising your productivity.
                                            </small>
			                            </div>
			                        </div>
			                    </div>
			                </div>
                            <div className="col-lg-4">
			                    <div className="ltn__search-by-place-item">
			                        <div className="search-by-place-img">
			                            <img src={publicUrl+"assets/img/images/ghc-callisto/amenities/amphitheater.png"} 
                                             alt="club house" />
			                        </div>
			                        <div className="search-by-place-info">
			            	            <h4>Indoor & Outdoor Sports</h4>
			                            <div className="search-by-place-btn">
			                                <small>GHR Callisto believes in creating a healthy community.
                                                    With our indoor and outdoor sports area, you can take care of your
                                                    fitness easily. 
                                            </small>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			    </div>
			  </div>
			</div>
        
        {/* What do we offer */}

        <div className="ltn__category-area ltn__product-gutter section-bg-1---">
			         <div className="container">
			            <div className="row">
			                <div className="col-lg-12">
                                <div className="section-title-area ltn__section-title-2--- text-center">
                                     <h1 className="section-title">What do we offer</h1>
                                </div>
			                </div>
			            </div>
			            <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center">
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
			                        <img src={publicUrl+"assets/img/images/ghc-callisto/advantages/location.png"} 
                                        alt="location" width="50px" height="40px" class="m-2"></img>
			                            <p className="category-title"><b>Location Advantage</b></p>
                                </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
                                    <img src={publicUrl+"assets/img/images/ghc-callisto/advantages/smarthome.png"}  
                                        alt="Smart home" width="50px" height="40px" class="m-2"></img>
                                    <p className="category-title"><b>Smat Home Automation </b></p>
			                    </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
                                <img src={publicUrl+"assets/img/images/ghc-callisto/advantages/open-area.jpg"} 
                                        alt="Open area" width="50px" height="40px" class="m-2"></img>
                                    <p className="category-title"><b>70% Open Area </b></p>
			                    </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
                                        <img src={publicUrl+"assets/img/images/ghc-callisto/advantages/vastu.jpg"} 
                                        alt="vastu" width="50px" height="40px" class="m-2"></img>
                                        <p className="category-title"><b>Vastu Compliant Homes</b></p>
			                    </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
                                        <img src={publicUrl+"assets/img/images/ghc-callisto/advantages/ev.png"} 
                                            alt="ev" width="50px" height="40px" class="m-2"></img>
                                        <p className="category-title"><b>EV Fast Charging Station</b></p>
			                    </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
                                     <img src={publicUrl+"assets/img/images/ghc-callisto/advantages/rain-water.jpg"} 
                                            alt="rain water" width="50px" height="40px" class="m-2"></img>
                                     <p className="category-title"><b>RainWater Harvesting</b></p>
			                    </div>
			                </div>
			                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			                    <div className="ltn__category-item ltn__category-item-5 text-center">
                                    <img src={publicUrl+"assets/img/images/ghc-callisto/advantages/grey-water.jpg"} 
                                          alt="grey water" width="50px" height="40px" class="m-2"></img>
                                     <p className="category-title"><b>Grey Water Treatment Plant</b></p>
			                    </div>
			                </div>
                           
			            </div>
			        </div>
			    </div>
            {/*End of what we offer*/}            
            {/* Overall project*/}

            <div className="ltn__feature-area section-bg-2 pt-30">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                            GHR Callisto is a complete and an expansive residential commune that’s planned for the future:
                            Take a glimpse at what it comprises of
                        </h6>
						
					</div>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-xl-3">
                        <div className="ltn__feature-item ltn__feature-item-7 ltn__feature-item-7-color-white">
                            <div className="ltn__feature-icon-title">
                                <h6>8.3 Acres Area</h6>
                            </div>
                        </div>
					</div>
					<div className="col-xl-3">
                        <div className="ltn__feature-item ltn__feature-item-7 ltn__feature-item-7-color-white">
                            <div className="ltn__feature-icon-title">
                                <h6>4 Towers</h6>
                            </div>
                            
                        </div>
					</div>
					<div className="col-xl-3">
                        <div className="ltn__feature-item ltn__feature-item-7 ltn__feature-item-7-color-white">
                            <div className="ltn__feature-icon-title">
                            <h6>11 Wings</h6>
                            </div>
                            
                        </div>
					</div>
                    <div className="col-xl-3">
                        <div className="ltn__feature-item ltn__feature-item-7 ltn__feature-item-7-color-white">
                            <div className="ltn__feature-icon-title">
                            <h6>18 Floors</h6>
                            </div>
                            
                        </div>
					</div>
                    <div className="col-xl-3">
                        <div className="ltn__feature-item ltn__feature-item-7 ltn__feature-item-7-color-white">
                            <div className="ltn__feature-icon-title">
                            <h6>1190 Premium Apartments</h6>
                            </div>
                            
                        </div>
					</div>
                    <div className="col-xl-3">
                        <div className="ltn__feature-item ltn__feature-item-7 ltn__feature-item-7-color-white">
                            <div className="ltn__feature-icon-title">
                            <h6>2, 3 & 4 BHK Homes</h6>
                            </div>
                            
                        </div>
					</div>
                    <div className="col-xl-3">
                        <div className="ltn__feature-item ltn__feature-item-7 ltn__feature-item-7-color-white">
                            <div className="ltn__feature-icon-title">
                            <h6>Flat SFT: 1195 sq.ft. - 1915 sq.ft.</h6>
                            </div>
                            
                        </div>
					</div>
                    <div className="col-xl-3">
                        <div className="ltn__feature-item ltn__feature-item-7 ltn__feature-item-7-color-white">
                            <div className="ltn__feature-icon-title">
                            <h6>Sky Villas: 3300 sq.ft. - 3385 sq.ft.</h6>
                            </div>
                            
                        </div>
					</div>

				</div>
				</div>
			</div>
            {/* End of overall project*/}

            {/*Trust Badges */}
            <div className="ltn__search-by-place-area before-bg-top bg-image-top pt-30">
			        <div className="container">
			            <div className="row">
			                <div className="col-lg-12">
			                    <div className="section-title-area ltn__section-title-2--- text-center---">
			                        <h1 className="section-title text-center">Trust Badges:</h1>
			                    </div>
			                </div>
			            </div>
			            <div className="row ltn__search-by-place-slider-1-active slick-arrow-1 go-top">
			                <div className="col-lg-4 h-15">
			                    <div className="ltn__search-by-place-item">
			                        <div className="search-by-place-img">
			                            <img src={publicUrl+"assets/img/images/ghc-callisto/trust-badges/tsrera.png"} 
                                             alt="rera" />
			                        </div>
                                <div className='pt-20'>
                                    <h3 className='text-center'>RERA Approved</h3>
                                </div>
			                        
			                    </div>
			                </div>
			                <div className="col-lg-4 h-15">
			                    <div className="ltn__search-by-place-item">
			                        <div className="search-by-place-img">
			                            <img src={publicUrl+"assets/img/images/ghc-callisto/trust-badges/igbc.png"} 
                                             alt="club house" />
			                        </div>
			                        
			                    </div>
			                </div>
			                <div className="col-lg-4">
			                    <div className="ltn__search-by-place-item">
                                <div className='pt-30'>
                                    <h3 className='text-center'>PMC & BIM Services by</h3>
                                </div>
			                        <div className="search-by-place-img">
			                            <img src={publicUrl+"assets/img/images/ghc-callisto/trust-badges/tata.png"} 
                                             alt="club house" />
			                        </div>
			                        
			                    </div>
			                </div>
			               
			    </div>
			  </div>
			</div>
            {/*End of trust badges*/}
            {/*Video */}

            <div style={{width:"100%", height:"100%"}} className="ltn__category-area ltn__product-gutter section-bg-1---">
			         <div className="container">
			            <div className="row">
			                <div className="col-lg-12">
                                <div className="section-title-area ltn__section-title-2--- text-center">
                                     <h1 className="section-title">Experiential Walk-through</h1>
                                </div>
			                </div>
			            </div>
			            <div className="row ltn__category">
                            <div>
                                <iframe width="1536" height="656" src="https://www.youtube.com/embed/W6_n565VpJs" 
                                title="GHR CALLISTO- Teaser || Kollur, Tellapur" frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                            </div>
			            </div>
			        </div>
			</div>
            {/*Vidoe end */}

		</div>
			
        }
}

export default GhrCallisto;