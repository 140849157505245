import React from 'react';
import Navbar from './global-components/navbar';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';


const ContactV1 = () => {
    return <div>
        <Navbar />
        
        <ContactInfo />
        <ContactForm formHeading="Submit your details, we'll get back to you ASAP" />
        
    </div>
}

export default ContactV1

