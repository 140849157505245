import React from 'react';
import Navbar from './global-components/navbar';
import AnvitaIvana from './section-components/anvita-ivana';

const Anvita_Ivana = () => {
    return <div>
        <Navbar />
        <AnvitaIvana />
        
        
    </div>
}

export default Anvita_Ivana;

