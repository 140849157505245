import React from 'react';
import Navbar from './global-components/navbar';
import BannerV6 from './section-components/banner-v6';
import Featuresv1 from './section-components/features-v1';


const Home_V7 = () => {
    return <div>
        <Navbar CustomClass="ltn__header-transparent gradient-color-2" /> 
        <BannerV6 />
        <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/>
        
    </div>
}

export default Home_V7

