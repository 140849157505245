import React, { Component } from 'react';
import Navbar from '../global-components/navbar';



class AboutV2 extends Component {

	
    render() {
			
        let publicUrl = process.env.PUBLIC_URL+'/'
		
    return <div className="ltn__about-us-area pt-120 pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-30">
			            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
			            <h4 className="section-title">Truevae Promoters</h4>
			            <p style={{marginLeft:"1vw"}}><b>Our Mantra:</b> 0% brokerage and 100% Happiness</p>
			          </div>			         
			        </div>
			      </div>
			      <div className="truevae-des-con">
				 		
						<dl>
							<dt>Who are we</dt>
								<dd>At Truevae, we understand that people everywhere are searching for a place to 
									call their own home or office. We want to make this search as interesting and fun as we 
									truly understand that finding the perfect place is much more than just an online search!
								</dd>
						
							<dt>What do we do</dt>
								<dd>We make the residential property buying journey of our clients easy, joyful by bringing in 
									great people, technology and values to one place
								</dd>
							<dt>How do we do it</dt>
								<dd>Truevae is combining top-notch modern technology with a team of experienced on-ground real 
									estate team and partners, to make the buying experience of a residential property easier & 
									seamless to our clients.
								</dd>
							<ul>
								<b>Our Methods:</b>
								<li>
									<dl>
										<dt>Online Services</dt>
										<dd>Search your dream home from our verified properties using original videos and pictures. All properties are verified and certified by us.</dd>
									</dl>
								</li>
								<li>
									<dl>
										<dt>Offline Services</dt>
										<dd>Professionals team on ground to identify the right property in your budget , location. We arrange site visit, negotiation, home loan service and after sales service to make your transaction smooth.</dd>
									</dl>
								</li>
							</ul>
						
					
						</dl>
				  </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV2