import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ContactForm from './contact-form';


class UnitedAvenues extends Component {

render() {  


let publicUrl = process.env.PUBLIC_URL+'/'



return <div className="ltn__page-details-area ltn__service-details-area mb-105">
				
			<div className='container' id="united-avenues-heading">
				<div className='row' id="un-main-desc">
					<h1 className="text-center">United Avenues</h1>
					<h5 className="text-center">14 Floors High-rise Greater Community with 2 Basement Parkings </h5>
					<span className="text-center d-block">No GST Charges & with Occupancy Certificate. Ready to move in 
							2&3 BHK Apartments.
					</span>
					<span className="text-center d-block">Flats available on ground & first floors. Offer Price: 
						<s>6500</s> 5999 Per Sq. Ft. Onwards 
					</span>
				</div>
			</div>
			<div className="container">
				<h4 id="project-desc-heading">Project description: </h4>
				<p className='text-left'>United Avenues, Manchirevula/Narsingi, one of the first projects of
					 the Team Anuktha Avenues, which is situated in a very strategical location of West Hyderabad. 
					 It lies in a close proximity to Nanakramguda, Gachibowli, Kokapet, Madhapur & Biodiversity.
				</p>
				<p className='text-left'>
					UNITED AVENUES consists of 202 homes, that are Vastu compliant, beautifully designed and 
					optimized with one of the best Architects of Hyderabad.
				</p>
			</div>
				
			<ContactForm formHeading="Book Site Visit" />
				
			<div className="image-carousel">
				<div className="container">
				

					<Carousel autoPlay infiniteLoop interval={2000} showThumbs={false}>
						<div>
							<img className='carousel-image' alt="United Avenues-1" src={publicUrl+"assets/img/images/united-avenues.jpg"} />
							<p className="legend">United Avenues-1</p>
						</div>
						<div>
							<img className='carousel-image' alt="Children Play Area" src={publicUrl+"assets/img/images/united-avenues-2.jpeg"} />
							<p className="legend">Children Play Area</p>
						</div>
						<div>
							<img className='carousel-image' alt="Volley Ball Court" src={publicUrl+"assets/img/images/united-avenues-3.jpeg"} />
							<p className="legend">Volley Ball Court</p>
						</div>
						<div>
							<img className='carousel-image' alt="Swimming Pool" src={publicUrl+"assets/img/images/united-avenues-4.jpg"} />
							<p className="legend">Swimming Pool</p>
						</div>
						<div>
							<img className='carousel-image' alt="United Avenues-2" src={publicUrl+"assets/img/images/united-avenues-5.jpg"} />
							<p className="legend">United Avenues-2</p>
						</div>
						<div>
							<img className='carousel-image' alt="EV-Charging Points" src={publicUrl+"assets/img/images/united-avenues-6.jpeg"} />
							<p className="legend">EV Charging Points</p>
						</div>
						<div>
							<img className='carousel-image' alt="Gym" src={publicUrl+"assets/img/images/united-avenues-7.jpeg"} />
							<p className="legend">Gym</p>
						</div>
						<div>
							<img className='carousel-image' alt="Indoor TT Table" src={publicUrl+"assets/img/images/united-avenues-8.jpeg"} />
							<p className="legend">Indoor TT Table</p>
						</div>
						<div>
							<img className='carousel-image' alt="Banquet Hall" src={publicUrl+"assets/img/images/united-avenues-9.jpeg"} />
							<p className="legend">Banquet Hall</p>
						</div>
					</Carousel>
					
				</div>
			</div>
						
			<div className="container p-3">
                    <div className="row align-items-start">
                        <h4 className='mx-auto'>Project Location:</h4>        
                    </div>
                    <div className="row align-items-start">
                        <div className="col">
                            <ul>
								<li>3 Min drive from Kokapet ~1.5 kms</li>
								<li>10 Min drive from Nanakramguda ~6 kms</li>
								<li>10 Min drive from Financial District ~6 kms</li>
								<li>15 Min drive from Gachibowli ~9 kms</li>
								<li>20 Min drive from Hi-tech City ~11 kms</li>
								<li>20 Min drive from Airport ~22 kms</li>
                            </ul>
                        </div>
					</div>
			</div>


			<div className="container">
				<div className="row">				
					<h4 id="amenities">Amenities: </h4>	
						<div className="d-flex flex-column align-items-center container">
							<div className="d-flex flex-row justify-content-around" style={{width:"60vw", height:"20vh"}}>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="party hall" style={{height:"9vh", width:"5vw"}} className="amenitie-img" 
										src={publicUrl+"assets/img/images/amineties/wedding.png"}/>
									<small className="text-center">Party Hall/Banquet Hall </small>
								</div>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="gym" style={{height:"9vh", width:"5vw"}} className="amenitie-img" src={publicUrl+"assets/img/images/amineties/treadmill.png"}/>
									<small className="text-center">Gym </small>
								</div>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="super market" style={{height:"9vh", width:"5vw"}} className="amenitie-img" src={publicUrl+"assets/img/images/amineties/shopping-cart.png"}/>
									<small className="text-center">Super Market </small>
								</div>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="indoor games" style={{height:"9vh", width:"5vw"}} className="amenitie-img" src={publicUrl+"assets/img/images/amineties/field.png"}/>
									<small className="text-center">Indoor Games </small>
								</div>
							</div>
							<div className="d-flex flex-row justify-content-around" style={{width:"60vw", height:"20vh"}}>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="day care" style={{height:"9vh", width:"7vw"}} className="amenitie-img" src={publicUrl+"assets/img/images/amineties/care.png"}/>
									<small className="text-center">Creche/Daycare  </small>
								</div>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="power backup" style={{height:"9vh", width:"7vw"}} className="amenitie-img" src={publicUrl+"assets/img/images/amineties/electric-generator.png"}/>
									<small className='text-center'>24/7 Power Backup  </small>
								</div>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="pool" style={{height:"9vh", width:"7vw"}} className="amenitie-img" src={publicUrl+"assets/img/images/amineties/swimming.png"}/>
									<small className='text-center'>Swimming pool </small>
								</div>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="atm" style={{height:"9vh", width:"7vw"}} className="amenitie-img" src={publicUrl+"assets/img/images/amineties/atm.png"}/>
									<small className="text-center">ATM</small>
								</div>
							</div>
							<div className="d-flex flex-row justify-content-around" style={{width:"60vw", height:"20vh"}}>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="guest rooms" style={{height:"9vh", width:"5vw"}} className="amenitie-img" src={publicUrl+"assets/img/images/amineties/bed.png"}/>
									<small className='text-center'> 2 Luxurious Guest Rooms </small>
								</div>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="child play area" style={{height:"9vh", width:"5vw"}} className="amenitie-img" src={publicUrl+"assets/img/images/amineties/playground.png"}/>
									<small>Children Play Area  </small>
								</div>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="cctv" style={{height:"9vh", width:"5vw"}} className="amenitie-img" src={publicUrl+"assets/img/images/amineties/cctv-camera.png"}/>
									<small className='text-center'> CCTV Cameras   </small>
								</div>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<img alt="yoga" style={{height:"9vh", width:"5vw"}} className="amenitie-img" src={publicUrl+"assets/img/images/amineties/exercise.png"}/>
									<small>Yoga & Aerobics Room </small>
								</div>
							</div>
						</div>
					
				</div>
			</div>
			

			
		</div>
			
        }
}

export default UnitedAvenues;