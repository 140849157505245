import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ContactForm from './contact-form';

class AnvitaIvana extends Component {

render() {  


let publicUrl = process.env.PUBLIC_URL+'/'
const downloadBroucher = ()=>{
    window.open(publicUrl+'assets/pdf/Ivana_Broucher.pdf');
}


return <div className="ltn__page-details-area ltn__service-details-area mb-105">
				
				<div className='container' id="anvita-ivana-heading">
				<div className='row' id="un-main-desc">
					<h1 className="text-center">Anvita Ivana</h1>
					<h5 className="text-center">By Anvita Group - 2, 3, 4 BHK Apartments</h5>
					<span className="text-center d-block">In Kolluru, West Hyderabad, Hyderabad. Possesion from Dec 2024</span>
					<span className="text-center d-block">Rs.5299.00/Sq.ft. Price excludes maintenance, floor rise cost, stamp duty, registration etc.  </span>
				</div>
				</div>
				<div className="image-carousel">
                <div className="container" id="imgs-carousel-ai">
                    <Carousel autoPlay infiniteLoop interval={2000} showThumbs={false}>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_1.webp"} alt="image-1" />
                        
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_2.webp"} alt="image-2" />
                        
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_3.webp"} alt="image-3" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_4.webp"} alt="image-4" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_5.webp"} alt="image-5" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_6.webp"} alt="image-6" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_7.webp"} alt="image-7" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_8.webp"} alt="image-8" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_9.webp"} alt="image-9" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_10.webp"} alt="image-10" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_11.webp"} alt="image-11" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_12.webp"} alt="image-12" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_13.webp"} alt="image-13" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_14.webp"} alt="image-14" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_15.webp"} alt="image-15" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_16.webp"} alt="image-16" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_17.webp"} alt="image-17" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_18.webp"} alt="image-18" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_19.webp"} alt="image-19" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_20.webp"} alt="image-20" />
                    </div>
                    {/* <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_21.webp"} alt="image-21" />
                    </div> */}
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_22.webp"} alt="image-22" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_23.webp"} alt="image-23" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_24.webp"} alt="image-24" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_25.webp"} alt="image-25" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_26.webp"} alt="image-26" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_27.webp"} alt="image-27" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_28.webp"} alt="image-28" />
                    </div>
                    {/* <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_29.webp"} alt="image-29" />
                    </div> */}
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_30.webp"} alt="image-30" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_31.webp"} alt="image-31" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_32.webp"} alt="image-32" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_33.webp"} alt="image-33" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_34.webp"} alt="image-34" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_35.webp"} alt="image-35" />
                    </div>
                    <div>
                        <img className='carousel-image' src={publicUrl+"assets/img/images/anvita-ivana/Anvita_36.webp"} alt="image-36" />
                    </div>
                    
                    </Carousel>
                </div>
                </div>
				<ContactForm formHeading="Submit your details and we'll get back to you ASAP" />
				<div className="container p-3">
                    <div className="row align-items-start">
                        <h4 className='mx-auto'>Project Highlights</h4>        
                    </div>
                    <div className="row align-items-start">
                        <div className="col">
                            <ul>
                                <li> Mini Zoo </li>
                                <li> Mini Forest </li>
                                <li> Adjcent to the ORR Service Road</li>
                                <li> Walkable distance for Exit Number 2, ORR </li>
                                <li> 5 mins drive from Neopolis </li>
                            </ul>
                        </div>
                        <div className="col">
                            <ul>
                                <li> 8 mins drive for Exit Number 1, ORR </li>
                                <li> 10 mins drive for Financial District </li>
                                <li> 30 mins drive for Raidurg Metro station, Hitech City </li>
                                <li> 30 mins drive for Lingampally Railway Station </li>
                                <li> 40 mins drive for RGI Airport </li>
                            </ul>
                        </div>
                       
                    </div>
				</div>
                <div className="container p-3">
                    <div className="row align-items-start">
                        <h4 className='mx-auto'>Anvita Ivana Overview</h4>        
                    </div>
                    <div className="row align-items-start">
                        <div className="col">
                            <span><b>Project Area: </b> 3.67 Acers</span>
                        </div>
                        <div className="col">
                            <span><b>Sizes: </b> 1260.00 Sq.Ft. to 5000.00 Sq.Ft.</span>
                        </div>
                        <div className="col">
                            <span><b>Project Size: </b> 2 Buildings - 417 Units</span>
                        </div>
                    </div>
                    <div className="row align-items-start">
                        <div className="col">
                            <span><b>Average Price: </b> Rs.5.30K Sq.Ft.</span>
                        </div>
                        <div className="col">
                            <span><b>Possesion Starts: </b> Dec 2024</span>
                        </div>
                        <div className="col">
                            <span><b>Configurations: </b> 2, 3, 4 BHK Apartments</span>
                        </div>
                        
                    </div>
                </div>
                <div className="container p-3">
                    <div className="row align-items-start">
                        <h4 className='mx-auto'>Amenities</h4>        
                    </div>
                   
                        <div className="row align-items-start">
                            <div className="col">
                                <span>&bull; Sun Deck</span>
                            </div>
                            <div className="col">
                                <span>&bull; Table tennis</span>
                            </div>
                            <div className="col">
                                <span>&bull; Terrace Garden</span>
                            </div>
                            <div className="col">
                                <span>&bull; Barbecue Area</span>
                            </div>
                            <div className="col">
                                <span>&bull; Gated Community</span>
                            </div>
                            <div className="col">
                                <span>&bull; Children's Paly Area</span>
                            </div>
                        </div>
            </div>
            <div className="container p-3">
                        <div className="row align-items-start">
                            <div className="col">
                                <span>&bull; Yoga/Meditation Area</span>
                            </div>
                            <div className="col">
                                <span>&bull; 24x7 Water Supply</span>
                            </div>
                            <div className="col">
                                <span>&bull; Power Backup</span>
                            </div>
                            <div className="col">
                                <span>&bull; Foosball</span>
                            </div>
                            <div className="col">
                                <span>&bull; Senior Citizen Steout</span>
                            </div>
                            <div className="col">
                                <span>&bull; Party Lawn</span>
                            </div>
                        </div>
            </div>
            <div className="container p-3">
                        <div className="row align-items-start">
                            <div className="col">
                                <span>&bull; Gazebo</span>
                            </div>
                            <div className="col">
                                <span>&bull; Club House</span>
                            </div>
                            <div className="col">
                                <span>&bull; CCTV</span>
                            </div>
                            <div className="col">
                                <span>&bull; Badminton Court</span>
                            </div>
                            <div className="col">
                                <span>&bull; Swimming Pool</span>
                            </div>
                            <div className="col">
                                <span className='fs-8'>&bull; Squash Court</span>
                            </div>
                        </div>
            </div>
            <div className="container p-3">
                        <div className="row align-items-start">
                            <div className="col-2">
                                <span>&bull; Cricket Pitch</span>
                            </div>
                            <div className="col-2">
                                <span>&bull; 24x7 Security</span>
                            </div>                        
                        </div>
            </div>
            <div className="container p-3">           
                        <div className="row align-items-start p-3">
                            <button className="btn theme-btn-1 btn-effect-1 p-2 text-center" type="button" onClick={downloadBroucher}>View the broucher</button>                      
                        </div>
                   
            </div>
                
			</div>
			
        }
}

export default AnvitaIvana;