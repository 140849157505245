import React, { Component } from 'react';

class ContactForm extends Component {

    render() {


	function submitForm(e){

		let form = document.getElementById('contact-form');
		
		e.preventDefault();

  		if(form.checkValidity() === false){
    		form.reportValidity();
    		return;
  			}
  		else{

    		let form = document.getElementById("contact-form");
			let formData = new FormData(form);
			
			function getParameter (parameterName){
				let parameters = new URLSearchParams(window.location.search);
				return parameters.get(parameterName);
			}
	
			let campaignSrc = getParameter("utm_source");
			let campaignMdm = getParameter("utm_medium");
			let campaignID = getParameter("utm_campaign");

			formData.append('campaignSource', campaignSrc);
			formData.append('campaignMedium', campaignMdm);
			formData.append('campaignId', campaignID);

			console.log(campaignSrc, campaignMdm, campaignID);
			console.log(formData);
			

			fetch('https://truevaepromoters.in/api/submitData.php',{
			method: 'post',
			body: formData,
			mode: "no-cors"
			}).then(function (response) {
			return response.text();
			}).then(function(text){
			document.getElementById('contact-form').reset();
			window.alert("Thank you for submitting your details, we'll get back to you ASAP reaact");
			//window.location.reload();

			}).catch(function(error){
			console.log(error);
			})

		}

	}
	let formHeading = this.props.formHeading;
    return <div className="ltn__contact-message-area">
				<div className="container-xxl">
				<div className="row">
					<div className="col-lg-12">
					<div className="ltn__form-box contact-form-box box-shadow white-bg">
						<h4 className="title-2">{formHeading}</h4>
						<form id="contact-form">
						<div className="row">
							<div className="col-md-6">
							<div className="input-item input-item-name ltn__custom-icon">
								<input type="text" id="userName" name="userName" placeholder="Enter your full name" required/>
							</div>
							</div>
							<div className="col-md-6">
							<div className="input-item input-item-email ltn__custom-icon">
								<input type="email" id="userEmail" name="userEmail" placeholder="Enter your email-id" required/>
							</div>
							</div>
							
							<div className="col-md-6">
							<div className="input-item input-item-phone ltn__custom-icon">
								<input type="tel" id="userPhoneNumber" name="userPhoneNumber" placeholder="Enter phone number" 
									minlength="10" maxlength="10" required />
							</div>
							</div>
						</div>
						
						<div className="btn-wrapper mt-0">
							<button onClick={submitForm} id="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Submit</button>
						</div>
						<p className="form-messege mb-0 mt-20" />
						</form>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ContactForm